<template>
  <div class="about">
    <div class="row justify-content-start">
      <div class="col-12 bg">
        <div style="margin-top: 20px;">
          <p class="title">隐形保镖电子质保查询系统</p>
        </div>
        <div class="row justify-content-start" style="margin: 10px 0px 0px 0px;">
          <div class="col-2"></div>
          <div class="col-8">
            <p class="describe">输入完整的手机号或质保卡号进行质保查询</p>
            <input type="text" class="form-control" style="height:30px" v-model="condition" />
          </div>
          <div class="col-2"></div>
        </div>
        <button
          class="button"
          style="margin: 20px 0px 0px 0px; width:150px;"
          @click="getQuality"
        >提交查询</button>
      </div>
    </div>
    <div class="row" style="padding: 30px 0px 30px 0px; background-color:#f3f3f2">
      <div class="col-1"></div>
      <div class="col-10 price">
        <header class="p-header">
          <h3 style="font-size: 20px; color: #ffffff; padding-top: 20px;">隐形保镖隐形车衣电子质保</h3>
          <p style="font-size: 15px; color: #ffffff; padding-top: 5px;">DIGITAL CERTIFICATE</p>
        </header>
        <table
          class="table table-bordered table-hover"
          style="width:95%; margin: 30px 15px 15px 15px;"
          v-if="quality.id"
        >
          <tbody style="font-size: 13px;">
            <tr>
              <td>姓名</td>
              <td>{{quality.name}}</td>
            </tr>
            <tr>
              <td>手机号</td>
              <td>{{quality.phone}}</td>
            </tr>
            <tr>
              <td>产品类型</td>
              <td>{{quality.productLevel}}</td>
            </tr>
            <tr>
              <td>汽车型号</td>
              <td>{{quality.carModel}}</td>
            </tr>
            <tr>
              <td>汽车颜色</td>
              <td>{{quality.carColor}}</td>
            </tr>
            <tr>
              <td>车牌号</td>
              <td>{{quality.carNumber}}</td>
            </tr>
            <tr>
              <td>车架号</td>
              <td>{{quality.carShelfNumber}}</td>
            </tr>
            <tr>
              <td>施工单位</td>
              <td>{{quality.workCompany}}</td>
            </tr>
            <tr>
              <td>施工技师</td>
              <td>{{quality.workStaff}}</td>
            </tr>
            <tr>
              <td>施工时间</td>
              <td>{{quality.workDateTime}}</td>
            </tr>
            <tr>
              <td>施工部位</td>
              <td>{{quality.workPart}}</td>
            </tr>
          </tbody>
        </table>
        <div class="row justify-content-start">
          <div class="col-1"></div>
          <div class="col-10">
            <p
              style="font-size:14px;"
            >为了保障您的消费权益，享受隐形保镖车衣厂家的质保，您的漆面保护膜必须从隐形保镖官方渠道购买，并经隐形保镖车衣合作门店施工，且在正常环境下使用。自装贴日起的质保年限内，厂商对仅因制造或者材料缺陷造成的开裂、起泡提供质保服务。此外，此质量保证仅在产品由隐形保镖车衣授权贴膜技师按照生产厂商提供的标准施工流程安装于汽车原厂漆表面，并使用推荐的保养方法维护的情况下适用。</p>
          </div>
          <div class="col-1"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const url = '/e-car/chinaybop-quality/getByCondition'
import axios from 'axios'
export default {
  components: {},
  data() {
    return {
      condition: null,
      quality: {
        id: null
      }
    }
  },
  created() {},
  methods: {
    getQuality() {
      if (this.condition.length !== 11) {
        return
      }
      axios
        .post(url, {
          phone: this.condition
        })
        .then(res => {
          this.quality = res.data[0]
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
<style scoped>
.bg {
  background-image: url('https://car-1301197907.cos.ap-beijing.myqcloud.com/price-1.jpg');
  height: 250px;
}
.title {
  font-size: 18px;
  color: #ffffff;
}
.describe {
  font-size: 12px;
  color: #ffffff;
}
.button {
  background-color: #ecbd4d;
  border: 1px solid #ecbd4d;
  border-radius: 4px;
  padding: 10px 15px 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
}

.button:hover {
  background-color: #ecbd4d;
}

.button:active {
  background-color: #ecbd4d;
}

.price {
  background-color: #ffffff;
  padding: 0;
}

.p-header {
  border-radius: 5px;
  background-color: #ecbd4d;
}

.p-header .title {
  color: #ffffff;
  padding: 30px 0 0 0;
}
</style>